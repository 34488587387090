<template>
    <el-table class="bank-files-container" :data="data" :indent="10" row-key="id" size="mini" @sort-change="sortChange" :row-class-name="tableRowClassName">
        <el-table-column type="expand" width="25">
            <template slot-scope="props">
                <BankFilesSubTable v-if="props.row.numberOfChildren > 1" :row="props.row" @openRefundModal="openRefundModal" />
                <BankFilesSubForm v-else :id="props.row.id" @openRefundModal="openRefundModal" />
            </template>
        </el-table-column>
        <el-table-column prop="" width="100" align="right">
            <template slot-scope="scope">
                <el-tag v-if="scope.row.status == 0" type="danger" effect="dark" style="width: 80px">Omatchad</el-tag>
                <el-tag v-if="scope.row.status == 1" color="#acbff1" type="" style="width: 80px">Matchad</el-tag>
                <el-tag v-if="scope.row.status == 2" type="warning" style="width: 80px">Skickad</el-tag>
            </template>
        </el-table-column>
        <el-table-column prop="bookingDate" label="Datum" sortable="custom" width="150">
            <template slot-scope="scope">
                <p>{{ formatDate(scope.row.bookingDate) }}</p>
            </template>
        </el-table-column>
        <el-table-column prop="text" label="Text" show-overflow-tooltip />
        <el-table-column prop="transactionType" label="Typ" width="140" />
        <el-table-column prop="originalCurrency" label="Valuta" width="80" />
        <el-table-column prop="amount" label="Belopp" width="140" align="right">
            <template slot-scope="scope">
                {{ scope.row.amount | swedishNumberFormat }}
            </template>
        </el-table-column>
        <el-table-column prop="balance" label="Balans" width="140" align="right">
            <template slot-scope="scope">
                {{ scope.row.balance | swedishNumberFormat }}
            </template>
        </el-table-column>
        <el-table-column prop="clientNumber" label="Klient nr" width="80" />
        <el-table-column prop="clientName" label="Klient" width="200" show-overflow-tooltip />
        <el-table-column prop="fileName" label="Camt-fil" width="300" show-overflow-tooltip />
        <!-- <el-table-column prop="" width="100" align="right">
            <template slot-scope="scope">
                <el-tooltip v-if="scope.row.hasComment" class="item" effect="dark" content="Kommentarer" placement="left">
                    <el-button @click="openCommentsModal(scope.row.id)" type="primary" class="p-1 mr-1">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                        </svg>
                    </el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="Detaljer" placement="left">
                    <router-link :to="'bank_files/' + scope.row.id">
                        <el-button type="" class="p-1">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="#415770">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM10 7v3m0 0v3m0-3h3m-3 0H7" />
                            </svg>
                        </el-button>
                    </router-link>
                </el-tooltip>
            </template>
        </el-table-column> -->
    </el-table>
</template>
<script>
import queryString from "query-string";
import moment from "moment";
import Api from "./../bank_files.api";

export default {
    components: {
        BankFilesSubTable: () => import(/* webpackChunkName: "BankFilesSubTable" */ "./BankFilesSubTable"),
        BankFilesSubForm: () => import(/* webpackChunkName: "BankFilesSubForm" */ "./BankFilesSubForm"),
    },
    props: {
        data: {
            type: Array,
            default: () => [],
        },
        children: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            loading: [],
        };
    },
    methods: {
        async expandChange(row) {
            console.log("🚀 - file: BankFilesTable.vue:79 - expandChange - row:", row.expanded);
            this.loading.push({ id: row.id });
            const children = await Api.getChildren(row.id);
            let findrow = this.data.find(item => item.id == row.id);
            findrow.children = [...children];
            const index = this.loading.findIndex(item => item.id === row.id);
            this.loading.splice(index, 1);
        },
        load(id) {
            console.log("🚀 - file: BankFilesTable.vue:91 - load - id:", id);
            let findrow = this.data.find(item => item.id == id);
            const children = findrow.children;
            return children;
        },
        formatDate(date) {
            return moment(date).format("YYYY-MM-DD");
        },

        openCommentsModal(id) {
            this.$emit("openCommentsModal", id);
        },

        openRefundModal(ids) {
            this.$emit("openRefundModal", ids);
        },

        sortChange(event) {
            const query = queryString.parse(window.location.search, { sort: false });
            query.sortBy = event.order ? event.prop : undefined;
            query.sortByType = event.order || undefined;
            this.$router.replace({ query }).catch(() => {});
            this.$emit("sortTypeChanged");
        },

        tableRowClassName() {
            //console.log("🚀 - file: BankFilesTable.vue:114 - tableRowClassName - row:", row);
            // if (row.status == 2) return "warning-row";
            return "";
            // let result = "";
            // if (row.isChildren) {
            //     result += "child-row";
            // }
            // if (!row.isChildren) {
            //     result += " parent-row";
            // }
            // return result;
        },
        childRowClassName({ row }) {
            let result = "";
            if (row.isChildren) {
                result += "child-row";
            }
            if (!row.isChildren) {
                result += " parent-row";
            }
            return result;
        },
    },
};
</script>
<style>
.el-table .warning-row {
    background: rgb(253, 253, 230);
}

.el-table .success-row {
    background: #f0f9eb;
}
.el-table .red-row {
    background: #ecd0d0;
}
/* .el-table__row:hover {
    background-color: #3415a5 !important;
} */

.bank-files-container .parent-row {
    font-weight: 600;
}
</style>
